<template>
  <b-card>
    <EditorVue :content.sync="form" />
    <div class="text-center mt20">
      <el-button @click="back">
        返 回
      </el-button>
      <el-button
        type="primary"
        size="small"
        @click="dialogVisible = true"
      >
        归 档
      </el-button>
    </div>
    <el-dialog
      title="归档"
      :visible.sync="dialogVisible"
      width="620px"
      @close="dialogClose"
      @open="dialogOpen"
    >
      <el-form
        v-if="dialogVisible"
        ref="form"
        v-loading="formLoading"
        :model="form"
        label-width="80px"
      >
        <el-form-item
          label="归档名称"
          prop="fileName"
          :rules="[ { required: true, message: '归档名称不能为空', trigger: 'change' }]"
        >
          <el-input v-model="form.fileName" />
        </el-form-item>
        <el-form-item
          label="文档目录"
          prop="libraryId"
          :rules="[ { required: true, message: '请选择文档目录', trigger: 'change' }]"
        >
          <el-cascader
            v-model="form.libraryId"
            :options="folders"
            popper-class="cas-class"
            :show-all-levels="false"
            style="width: 100%;"
            :props="{ emitPath: false, expandTrigger: 'hover', value: 'id', label: 'libraryName',checkStrictly: true,showAllLevels:false }"
          />
        </el-form-item>
        <el-form-item
          label="说明"
        >
          <el-input
            v-model="form.remark"
            type="textarea"
          />
        </el-form-item>
      </el-form>

      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogVisible = false">
          取 消
        </el-button>
        <el-button
          type="primary"
          @click="submitForm"
        >
          提 交
        </el-button>
      </div>
    </el-dialog>
  </b-card>
</template>
<script>
import EditorVue from '@/@core/components/editor-vue/EditorVue.vue'
import { PrivacyOrgpreview, PrivacyOrgFileSave, RoleDel } from '@/api/enterprise/enterprise'
import { error, success } from '@/@core/utils/utils'
import { GetLibraryOptions } from '@/api/regime/regime'

export default {
  components: { EditorVue },
  data() {
    return {
      dialogVisible: false,
      form: {
        remark: '',
        fileName: '',
        content: '',
        libraryId: '',
      },
      formLoading: false,
      folders: [],
      hasSave: true,
    }
  },
  created() {
    this.getContent()
    this.getLibraryOptions()
  },
  mounted() {
    this.navActiveInit(1, '隐私组织', '管理框架')
  },
  destroyed() {
    this.navActiveInit(2, '隐私组织', '管理框架', '/policy-organization')
  },
  methods: {
    getContent() {
      PrivacyOrgpreview().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.form.content = resData.data
        }
      })
    },
    back() {
      if (this.hasSave) {
        this.$confirm('当前文档还未保存，确定返回吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          closeOnClickModal: false,
        }).then(() => {
          this.$router.push({ path: '/policy/organization' })
        })
      }
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.formLoading = true
          PrivacyOrgFileSave(this.form).then(res => {
            this.formLoading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.dialogVisible = false
              this.$router.push({ path: '/policy/organization' })
            } else {
              error(resData.msg)
            }
          }).catch(()=>{
            this.formLoading = false
          })
        }
      })
    },
    dialogClose() {},
    dialogOpen() {},
    getLibraryOptions() {
      GetLibraryOptions().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.library = resData.data
          this.folders = this.listToTree(this.library)
        }
      })
    },
    listToTree(list) {
      const map = {}
      for (const item of list) {
        item.label = item.libraryName
        map[item.id] = item
      }
      for (const item of list) {
        if (!(item.parentId > 0)) {
          continue
        }
        const parent = map[item.parentId]
        if (parent) {
          if (parent.children === undefined) {
            parent.children = []
          }
          parent.children.push(item)
        }
      }
      return list.filter(i => i.parentId === 0)
    },
  },
}
</script>
<style lang="scss">
.cas-class .el-radio__inner{
  display: none;
}
</style>
