import {
  downloadByGet, get, post, getByBlob,
} from '@/libs/axios'

export const GetRegimeListByPage = params => post('/regime/GetRegimeListByPage', params)
export const GetAllPiaAssessRisk = params => post('/pia/risk/GetAllPiaAssessRisk', params)
export const DeleteRegimeByIds = params => post('/regime/DeleteRegimeByIds', params)
export const DownloadFile = id => downloadByGet('/regime/DownloadRegimeFile', { id })
export const RemigeReadFile = id => getByBlob(`/regime/preview?id=${id}`)
export const saveOrUpdateRegime = (params, form) => {
  const submitUrl = form.id ? '/regime/UpdateRegime' : '/regime/CreateRegime'
  return post(submitUrl, params)
}
export const saveOrUpdateRegimeByTemplate = form => {
  const submitUrl = form.id ? '/regime/UpdateRegimeByTemplate' : '/regime/CreateRegimeByTemplate'
  return post(submitUrl, form)
}
export const saveOrUpdateLibrary = form => {
  const submitUrl = form.id ? '/regime/library/UpdateLibrary' : '/regime/library/CreateLibrary'
  return post(submitUrl, form)
}
export const GetRegimeById = params => post('/regime/GetRegimeById', params)
export const CreateLabel = params => post('/regime/label/CreateLabel', params)
export const DeleteLabelByIds = params => post('/regime/label/DeleteLabelByIds', params)
export const GetLabelList = () => get('/regime/label/GetLabelList')
export const GetAllLibrary = () => get('/regime/library/AllLibrary')
export const GetTemplateOptions = () => get('/regime/template/GetTemplateOptions')
export const GetLibraryOptions = () => get('/regime/library/GetLibraryOptions')
export const DeleteLibraryById = params => post('/regime/library/DeleteLibraryById', params)
export const GetTemplateContent = id => get(`/regime/template/GetTemplateContent?templateCode=${id}`)
